<template>
  <div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-60 md-size-50"
      ></div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-40 md-size-50"
      >
        <div
          class="crud-add-button"
          v-if="$store.getters.getPermission('geofences_create')"
        >
          <div>
            <router-link :to="{ name: 'geofenceCreate' }">
              <span class="material-icons"> add_circle </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <datatables-wrapper
      @optionsChanged="getItems"
      @updateTable="getItems"
      :loading="loading"
      :data="data"
      :headers="headers"
      filterType="simple"
      :parameters="['name']"
      @rowClick="rowClick"
    />
  </div>
</template>

<script>
import DatatablesWrapper from "@/components/Datatables/DatatablesWrapper.vue";
export default {
  name: "zones-crud",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  components: {
    DatatablesWrapper,
  },
  data() {
    return {
      selected: [],
      filter: "",
      data: {},
      loading: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Timestamp", value: "timestamp" },
      ],
    };
  },
  methods: {
    getZones(page = 1) {
      this.$store
        .dispatch("zone/getZonesTable", {
          page,
          filter: this.filter,
        })
        .then((data) => {
          this.zones = data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getItems(options) {
      this.loading = true;
      this.$store
        .dispatch("zone/getZones", options)
        .then((data) => (this.data = data))
        .finally(() => (this.loading = false));
    },
    rowClick(event) {
      this.$router.push({
        name: "geofence",
        params: { geofence: event.id, tab: "details" },
      });
    },
  },
  mounted() {
    this.getItems({ page: 1 });
  },
};
</script>
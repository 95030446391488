<template>
  <div class="container-fluid">
    <v-tabs v-model="tab">
      <v-tab v-if="$store.getters.getPermission('dashboards_view')"
        >{{$t('workspace.workspaceTab.customDashboard')}}</v-tab
      >
      <v-tab v-if="$store.getters.getPermission('geofences_view')"
        >{{$t('workspace.workspaceTab.geofence')}}</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tab" class="mt-2 rounded-md">
      <v-tab-item>
        <md-card>
          <md-card-header :data-background-color="getTheme">
            <h4 class="title">{{$t('workspace.workspaceTab.customDashboard')}}</h4>
          </md-card-header>
          <md-content class="p-5">
            <dynamic-dashboard-crud></dynamic-dashboard-crud>
          </md-content>
        </md-card>
      </v-tab-item>
      <v-tab-item>
        <md-card>
          <md-card-header :data-background-color="getTheme">
            <h4 class="title">{{$t('workspace.workspaceTab.geofence')}}</h4>
          </md-card-header>
          <md-content class="p-5">
            <geo-fence-crud></geo-fence-crud>
          </md-content>
        </md-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import DynamicDashboardCrud from "@/components/DynamicDashboard/Crud/DynamicDashboardCrud.vue";
import GeoFenceCrud from "@/components/GeoFence/GeoFenceCrud";
import { mapGetters } from "vuex";
export default {
  components: {
    DynamicDashboardCrud,
    GeoFenceCrud,
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  methods: {
    setTab() {
      if (this.$route.params.tab) this.tab = this.$route.params.tab;
    },
  },
  mounted() {
    this.setTab();
  },
};
</script>